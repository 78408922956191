import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Card, Table, Button, Skeleton } from 'antd'
import { DollarOutlined, CheckOutlined, HistoryOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'

import { onSnapshot, collection, query, where, limit } from 'firebase/firestore'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { pending } from '../../const/bots'
import { billingStatus } from '../../const/billing'
import { dateFormat } from '../../const/misc'

import './Dashboard.css'

const now = (Date.now()) / (1000 * 60 * 60)

const List = () => {
  const navigate = useNavigate()
  const { db } = useAuth()
  const { getPageNo, onPageChange } = useLayoutContext()

  const [value, setValue] = useState([])

  useEffect(() => {
    const unsub = onSnapshot(query(collection(db, 'billings'), where('s', '==', 'pending'), where('c', '==', 'USDT'), limit(50)), { includeMetadataChanges: true }, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        const d = doc.data()
        const start = (d.date.seconds) / (60 * 60)
        const duration = Math.ceil((now - start) / 24)
        return {
          ...d,
          id: doc.id,
          am: d.am + (d?.aam ?? 0),
          duration: duration
        }
      })
      data.sort((x, y) => y.date.seconds - x.date.seconds)
      setValue(data)
    })
    return () => unsub()
  }, [db])

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      width: 100,
      render: text => dayjs(text.seconds * 1000).format(dateFormat)
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      width: 100,
      render: text => <span style={{ color: (text <= pending) ? '#15dd15' : '#dd1515' }}>{text}</span>
    },
    {
      title: 'Coin',
      dataIndex: 'c',
      width: 100
    },
    {
      title: 'Network',
      dataIndex: 'n',
      width: 100
    },
    {
      title: 'Address',
      dataIndex: 'from'
    },
    {
      title: 'Billed',
      dataIndex: 't',
      width: 100
    },
    {
      title: 'Paid',
      dataIndex: 'am',
      width: 100,
      render: data => new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(data)
    },
    {
      title: 'Status',
      dataIndex: 's',
      width: 130,
      render: (text) => <span style={{ color: (text === 'success') ? '#15dd15' : '#dd1515' }}>{billingStatus[text]}</span>
    },
    {
      title: 'Remark',
      dataIndex: 'r',
      width: 200
    },
    {
      title: 'Operation',
      dataIndex: 'Operation',
      render: (_, row) => (
        <div>
          {(row.s === 'pending') && (
            <>
              <Button style={{ borderRadius: 4, marginLeft: 16 }} type='primary' size='middle' onClick={() => navigate(`/admin/dashboard/billings/${row.id}/approve`)}><DollarOutlined /></Button>
              <Button style={{ borderRadius: 4, marginLeft: 16 }} size='middle' onClick={() => navigate(`/admin/dashboard/billings/history${row.from}`)}><HistoryOutlined /></Button>
            </>
          )}
        </div>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div className='dashboard-page'>
      <Card
        title='Billing List'
        styles={{
          header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
          body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
        }}
      >
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button style={{ borderRadius: '4px' }} onClick={() => navigate('/admin/dashboard/billings/approval')} type='primary'><CheckOutlined />Approval</Button>
        </div>
        <Table
          bordered
          rowKey='id'
          columns={columns}
          dataSource={value}
          pagination={{
            total: value?.length ?? 0,
            current: getPageNo('billingList'),
            onChange(page) {
              onPageChange('billingList', page)
            }
          }}
        />
      </Card>
    </div>
  )
}

export default List
