import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Modal, Row, Col, Button, Input, InputNumber, Select, Switch, Radio, Space, Collapse, message, Upload, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined, SettingOutlined, DownloadOutlined, InboxOutlined, PlayCircleOutlined, PauseCircleOutlined, StopOutlined } from '@ant-design/icons'

import { onSnapshot, collection, doc, getDoc, setDoc, query, where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'

import { useAuth } from '../../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../../contexts/LayoutContextProvider'
import { getTickers } from '../../../utils/tickers'
import { pickBy } from '../../../utils/tools'
import { getExLogo, downloadJsonFile, parseJsonFile, urlValidator } from '../../../utils/tools'
import { exchanges, timeFrame } from '../../../const/bots'

import '../Bot.css'

const Form = () => {
  const [messageApi, contextHolderMessage] = message.useMessage()
  const [modal, contextHolderModal] = Modal.useModal()
  const navigate = useNavigate()
  const { db, functions, uid, admin, tester } = useAuth()
  const { getTK, setTK, getKey, setKey } = useLayoutContext()
  const { bid } = useParams()
  const [botId, setBotId] = useState(bid)
  const [alertId, setAlertId] = useState()
  const [btnLoading, setBtnLoading] = useState(false)
  const [messageCode, setMessageCode] = useState('')
  const [exchange, setExchange] = useState('binance')
  const [apiUri, setApiUri] = useState('')
  const [apiType, setApiType] = useState()
  const [types, setTypes] = useState([])
  const [tickers, setTickers] = useState([])
  const [ticker, setTicker] = useState()
  const [orderType, setOrderType] = useState('market')
  const [positionSize, setPositionSize] = useState('fb')
  const [tvps, setTvps] = useState()
  const [stepSize, setStepSize] = useState()
  const [tokens, setTokens] = useState([])
  const [keys, setKeys] = useState([])
  const [active, setActive] = useState(true)

  const mode = bid ? 'Edit' : 'Create'

  const [botForm] = AntdForm.useForm()

  useEffect(() => {
    const unsub = onSnapshot(query(collection(db, 'tokens'), where('uid', '==', uid)), { includeMetadataChanges: true }, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return {
          value: doc.id,
          name: doc.data().name
        }
      })
      data.sort((x, y) => x.name.localeCompare(y.name))
      setTokens(data)
    })
    return () => unsub()
  }, [db, uid])

  const { value, loading } = useAsync(async () => {
    let ks = getKey()
    if (!ks) {
      const getKeys = httpsCallable(functions, 'getKeys-getKeys')
      const { data } = await getKeys({ uid: uid })
      data.sort((x, y) => x.name.localeCompare(y.name))
      ks = data.map((o) => ({
        value: o.id,
        name: o.name,
        ex: o.ex,
        ksi: o.ksi
      }))
    }
    setKey(ks)
    setKeys(ks)

    if (!bid) {
      const ex = exchanges.find((o) => o.value === 'binance')
      setApiUri(ex['tv']['apiUri'])
      setTypes(ex['tv']['apiTypes'])
      return {
        uid: uid,
        bot: 'tv',
        ex: 'binance',
        active: true,
        aa: false,
        ordt: 'market',
        pos: 'fb',
        ro: 'cobs'
      }
    }
    const snap = await getDoc(doc(db, 'bots', bid))
    const data = snap.data()
    const key = `${data.ex}${data.type}`
    const tks = getTK(key)
    let tickerData
    if (!tks) {
      tickerData = await getTickers(data.ex, data.type)
      setTK(key, tickerData)
    } else {
      tickerData = tks
    }
    const tickerList = tickerData.map((o) => ({ ...o, value: o.tk }))
    const getTicker = tickerList.find((o) => o.value === data.tk)
    const step = (getTicker) ? (getTicker.scnt / Math.pow(10, getTicker.sdec)) : 0
    const ex = exchanges.find((o) => o.value === data.ex)
    setExchange(data.ex)
    setApiUri(ex['tv']['apiUri'])
    setTypes(ex['tv']['apiTypes'])
    setApiType(data.type)
    setTickers(tickerList)
    setTicker(getTicker)
    setStepSize(step)
    setOrderType(data.ordt)
    setPositionSize(data.pos)
    setTvps(data?.tvps)
    setAlertId(snap.id + data.cs)
    setActive(data.active)
    genCode(data.name, snap.id + data.cs, data.cx, data.px, (data.pos === 'tv') ? data.tvps : 'not')
    return {
      ...data,
      id: snap.id,
      uid: uid
    }
  }, [bid])

  const handleSubmit = async (formData) => {
    try {
      setBtnLoading(true)
      if (formData.balmax && formData.balmax === 0) delete formData.balmax
      let data = pickBy(formData)
      let docId = alertId
      if (botId) {
        await setDoc(doc(db, 'bots', botId), data, { merge: true })
      } else {
        const createBot = httpsCallable(functions, 'createBot-createBot')
        const botData = await createBot(data)
        docId = botData.data.id + botData.data.cs
        setAlertId(docId)
        setBotId(botData.data.id)
      }
      if (!bid) {
        genCode(formData.name, docId, formData.cx, formData.px, formData?.tvps)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setBtnLoading(false)
    }
  }

  const genCode = (name, id, cx, px, tvps) => {
    const preCode = pickBy({
      name: name,
      id: id,
      interval: '{{interval}}',
      cx: (!isNaN(cx)) ? `{{plot_${cx}}}` : null,
      px: (!isNaN(px)) ? `{{plot_${px}}}` : null,
      ps: (!isNaN(tvps)) ? `{{plot_${tvps}}}` : null
    })
    const code = JSON.stringify(preCode, null, 2)
    setMessageCode(code)
  }

  const onExchangeChange = (value) => {
    const ex = exchanges.find((o) => o.value === value)
    setExchange(value)
    setApiUri(ex['tv']['apiUri'])
    setTypes(ex['tv']['apiTypes'])
    botForm.setFieldsValue({
      ...botForm.getFieldsValue(),
      kid: '',
      type: '',
      tk: ''
    })
  }

  const onTypeChange = async (value) => {
    try {
      const key = `${exchange}${value}`
      const tks = getTK(key)
      let tickerData
      if (!tks) {
        tickerData = await getTickers(exchange, value)
        setTK(key, tickerData)
      } else {
        tickerData = tks
      }
      const list = tickerData.map((o) => ({ ...o, value: o.tk }))
      setTickers(list)
      setApiType(value)
      const data = botForm.getFieldsValue()
      botForm.setFieldsValue({
        ...data,
        tk: ''
      })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const onTickerChange = (value) => {
    const data = (tickers).find((o) => o.value === value)
    const step = (data) ? (data.scnt / Math.pow(10, data.sdec)) : 0
    setTicker(data)
    setStepSize(step)
  }

  const downloadJson = () => {
    const formData = botForm.getFieldsValue()
    delete formData.uid
    delete formData.kid
    delete formData.ksi
    delete formData.tid
    delete formData.aa
    delete formData.whl
    const code = pickBy(formData)
    downloadJsonFile(code, code.name)
  }

  const uploadJson = async (e) => {
    const data = await parseJsonFile(e.file.originFileObj)
    const key = `${data.ex}${data.type}`
    const tks = getTK(key)
    let tickerData
    if (!tks) {
      tickerData = await getTickers(data.ex, data.type)
      setTK(key, tickerData)
    } else {
      tickerData = tks
    }
    const tickerList = tickerData.map((o) => ({ ...o, value: o.tk }))
    const getTicker = tickerList.find((o) => o.value === data.tk)
    const step = (getTicker) ? (getTicker.scnt / Math.pow(10, getTicker.sdec)) : 0
    const ex = exchanges.find((o) => o.value === data.ex)
    setExchange(data.ex)
    setApiUri(ex['tv']['apiUri'])
    setTypes(ex['tv']['apiTypes'])
    setApiType(data.type)
    setTickers(tickerList)
    setTicker(getTicker)
    setStepSize(step)
    setOrderType(data.ordt)
    setPositionSize(data.pos)
    botForm.setFieldsValue({
      ...botForm.getFieldsValue(),
      ...data
    })
  }

  const updateStatus = async (status, close) => {
    try {
      setBtnLoading(true)
      await setDoc(doc(db, 'bots', botId), {
        active: status
      }, { merge: true })
      if (close) {
        const closePosition = httpsCallable(functions, 'closePosition-closePosition')
        await closePosition({ id: botId })
      }
      setActive(status)
    } catch (error) {
      console.log(error)
    } finally {
      setBtnLoading(false)
    }
  }

  if (loading) return <Skeleton></Skeleton>
  return (
    <div className='bot-page'>
      <Row gutter={16}>
        <Col flex='auto'>
          <Card
            title={`${mode} TradingView Bot`}
            styles={{
              header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
              body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
            }}
          >
            <AntdForm
              labelCol={{ span: 8 }}
              onFinish={handleSubmit}
              initialValues={value}
              form={botForm}
              name='botForm'
            >
              <AntdForm.Item name='uid' hidden={true}>
                <Input />
              </AntdForm.Item>
              <AntdForm.Item name='bot' hidden={true}>
                <Input />
              </AntdForm.Item>
              <AntdForm.Item name='ksi' hidden={true}>
                <Input />
              </AntdForm.Item>
              <Collapse
                defaultActiveKey={['1']}
                expandIconPosition='end'
                items={[
                  {
                    key: '1',
                    label: 'Bot Setting',
                    extra: <SettingOutlined />,
                    children: <>
                      <AntdForm.Item name='name' label='Bot Name' rules={[{ required: true, message: 'Please input Bot Name' }]}>
                        <Input />
                      </AntdForm.Item>
                      <AntdForm.Item name='ex' label='Exchange' rules={[{ required: true, message: 'Please select Exchange' }]}>
                        <Select
                          placeholder='Select Exchange'
                          showSearch
                          optionFilterProp='value'
                          filterOption={(input, option) => {
                            return (option.value || '').toLowerCase().indexOf(input.toLowerCase()) !== -1
                          }}
                          onChange={(v) => onExchangeChange(v)}
                          disabled={mode === 'Edit'}
                        >
                          {(exchanges.filter((o) => (o.tv && (!o.test || (o.test && tester)))).map((o) => (
                            <Select.Option key={o.value} value={o.value}>{getExLogo(o.value, 64)}</Select.Option>
                          )))}
                        </Select>
                      </AntdForm.Item>
                      <AntdForm.Item name='type' label='API Type' rules={[{ required: true, message: 'Please select API Type' }]}>
                        <Select
                          placeholder='Select API Type'
                          showSearch
                          optionFilterProp='value'
                          filterOption={(input, option) => {
                            return (option.value || '').toLowerCase().indexOf(input.toLowerCase()) !== -1
                          }}
                          onChange={(v) => onTypeChange(v)}
                        >
                          {((types).map((o) => (
                            <Select.Option key={o.value} value={o.value}>{o.name}</Select.Option>
                          )))}
                        </Select>
                      </AntdForm.Item>
                      <AntdForm.Item name='tk' label='Ticker' rules={[{ required: true, message: 'Please select Ticker' }]}>
                        <Select
                          placeholder='Select Ticker'
                          showSearch
                          optionFilterProp='value'
                          filterOption={(input, option) => {
                            return (option.value || '').toLowerCase().indexOf(input.toLowerCase()) !== -1
                          }}
                          onChange={(v) => onTickerChange(v)}
                        >
                          {((tickers).map((o) => (
                            <Select.Option key={o.value} value={o.value}>{o.value}</Select.Option>
                          )))}
                        </Select>
                      </AntdForm.Item>
                      <AntdForm.Item name='kid' label='Exchange API' rules={[{ required: true, message: 'Please select Exchange API' }]}>
                        <Select
                          placeholder='Select Exchange API'
                          showSearch
                          optionFilterProp='value'
                          filterOption={(input, option) => {
                            return (option.value || '').toLowerCase().indexOf(input.toLowerCase()) !== -1
                          }}
                          onChange={(v) => {
                            const key = keys.find((o) => o.value === v)
                            botForm.setFieldsValue({
                              ...botForm.getFieldsValue(),
                              ksi: key.ksi
                            })
                          }}
                        >
                          {(((keys || []).filter((o) => o.ex === exchange)).map((o) => (
                            <Select.Option key={o.value} value={o.value}>{o.name}</Select.Option>
                          )))}
                        </Select>
                      </AntdForm.Item>
                      <AntdForm.Item name='tid' label='Alert API'>
                        <Select
                          placeholder='Select Alert API'
                          showSearch
                          optionFilterProp='value'
                          filterOption={(input, option) => {
                            return (option.value || '').toLowerCase().indexOf(input.toLowerCase()) !== -1
                          }}
                        >
                          {((tokens || []).map((o) => (
                            <Select.Option key={o.value} value={o.value}>{o.name}</Select.Option>
                          )))}
                        </Select>
                      </AntdForm.Item>
                      <AntdForm.Item name='aa' label='Enable Alert' labelCol={{ span: 10 }}>
                        <Switch checkedChildren='Yes' unCheckedChildren='No' />
                      </AntdForm.Item>
                      <AntdForm.Item
                        name='whl'
                        label='Webhook for Logging'
                        rules={[
                          {
                            validator: (_, value) => {
                              if (!value) {
                                return Promise.resolve()
                              }
                              if (!urlValidator(value)) {
                                return Promise.reject(new Error('You must fill a valid url'))
                              }
                              if (value.indexOf('script.google.com/macros') === -1) {
                                return Promise.reject(new Error('You must fill a url from Google Sheet Only'))
                              }
                              return Promise.resolve()
                            }
                          }
                        ]}>
                        <Input />
                      </AntdForm.Item>
                    </>
                  }
                ]}
              />
              <Collapse
                defaultActiveKey={['1']}
                expandIconPosition='end'
                items={[
                  {
                    key: '1',
                    label: 'TradingView Setting',
                    extra: <SettingOutlined />,
                    children: <>
                      <AntdForm.Item name='tf' label='Time Frame' rules={[{ required: true, message: 'Please select Time Frame' }]}>
                        <Select
                          placeholder='Select Time Frame'
                        >
                          {((timeFrame['tv']).map((o) => (
                            <Select.Option key={o.value} value={o.value}>{o.name}</Select.Option>
                          )))}
                        </Select>
                      </AntdForm.Item>
                      <AntdForm.Item name='cx' label='Current x (Plot #)' rules={[{ required: true, message: 'Please select Current x (Plot #)' }]}>
                        <Select
                          placeholder='Select Current x (Plot #)'
                          onChange={(v) => {
                            if (bid) {
                              const d = botForm.getFieldsValue()
                              genCode(d.name, alertId, v, d?.px, d?.tvps)
                              messageApi.open({
                                type: 'warning',
                                content: 'Current x has changed, please update your TradingView Message Code',
                                duration: 10,
                              })
                            }
                          }}
                        >
                          {(([...Array(20).keys()]).map((o) => (
                            <Select.Option key={o} value={o}>{o}</Select.Option>
                          )))}
                        </Select>
                      </AntdForm.Item>
                      <AntdForm.Item name='px' label='Previous x (Plot #)' rules={[{ required: true, message: 'Please select Previous x (Plot #)' }]}>
                        <Select
                          placeholder='Select Previous x (Plot #)'
                          onChange={(v) => {
                            if (bid) {
                              const d = botForm.getFieldsValue()
                              genCode(d.name, alertId, d?.cx, v, d?.tvps)
                              messageApi.open({
                                type: 'warning',
                                content: 'Previous x has changed, please update your TradingView Message Code',
                                duration: 10,
                              })
                            }
                          }}
                        >
                          {(([...Array(20).keys()]).map((o) => (
                            <Select.Option key={o} value={o}>{o}</Select.Option>
                          )))}
                        </Select>
                      </AntdForm.Item>
                    </>
                  }
                ]}
              />
              <Collapse
                defaultActiveKey={['1']}
                expandIconPosition='end'
                items={[
                  {
                    key: '1',
                    label: 'Order Setting',
                    extra: <SettingOutlined />,
                    children: <>
                      <AntdForm.Item name='pos' label='Position Size Lot (1X)' rules={[{ required: true, message: 'Please Select Position Size' }]}>
                        <Radio.Group
                          options={
                            (admin || tester) ? ([
                              { label: 'Fixed Balance', value: 'fb' },
                              { label: 'Fixed Asset', value: 'fa' },
                              { label: 'Variable Balance', value: 'vb' },
                              { label: 'Asset Size from TV', value: 'tv' }
                            ]) : ([
                              { label: 'Fixed Balance', value: 'fb' },
                              { label: 'Fixed Asset', value: 'fa' },
                              { label: 'Variable Balance', value: 'vb' }
                            ])
                          }
                          optionType='button'
                          buttonStyle='solid'
                          onChange={(e) => {
                            if (bid) {
                              const d = botForm.getFieldsValue()
                              const ps = (e.target.value === 'tv') ? tvps : 'not'
                              genCode(d.name, alertId, d?.cx, d?.px, ps)
                            }
                            setPositionSize(e.target.value)
                          }}
                        />
                      </AntdForm.Item>
                      {(positionSize === 'fa') ? (
                        <AntdForm.Item name='fpos' label={`Fixed Asset Size ${(ticker) ? `(${ticker.trdas})` : ''}`} labelCol={{ span: 10 }} rules={[{ required: true, message: 'Please input Fixed Asset Size' }]}>
                          <InputNumber
                            min={0}
                            step={stepSize}
                            precision={(ticker && ticker.sdec > 0) ? ticker.sdec : 0}
                          />
                        </AntdForm.Item>
                      ) : (positionSize === 'fb') ? (
                        <AntdForm.Item name='bfpos' label={`Fixed Balance Size ${(ticker) ? `(${ticker.balas})` : ''}`} labelCol={{ span: 10 }} rules={[{ required: true, message: 'Please input Fixed Balance Size' }]}>
                          <InputNumber
                            min={0}
                            precision={(ticker && ticker.pdec > 0) ? ticker.pdec : 0}
                          />
                        </AntdForm.Item>
                      ) : (positionSize === 'vb') ? (
                        <>
                          <AntdForm.Item name='vpos' label={`Balance Proportion (% of ${(ticker) ? ticker.balas : ''})`} labelCol={{ span: 10 }} rules={[{ required: true, message: 'Please input Balance Proportion' }]}>
                            <InputNumber
                              min={0}
                              max={(apiType !== 'spot') ? 12500 : 100}
                            />
                          </AntdForm.Item>
                          {(apiType !== 'spot') && (
                            <>
                              <AntdForm.Item name='vbt' label='Balance Type' labelCol={{ span: 10 }} rules={[{ required: true, message: 'Please Select Balance Type' }]}>
                                <Radio.Group
                                  options={[
                                    { label: 'Margin Balance', value: 'm' },
                                    { label: 'Wallet Balance', value: 'w' }
                                  ]}
                                  optionType='button'
                                  buttonStyle='solid'
                                />
                              </AntdForm.Item>
                            </>
                          )}
                          <AntdForm.Item name='vbu' label='Balance Update' labelCol={{ span: 10 }} rules={[{ required: true, message: 'Please select Balance Update' }]}>
                            <Radio.Group
                            >
                              <Space direction='vertical'>
                                <Radio value='mj'>When Major Signal Change</Radio>
                                <Radio value='et'>Every Trade</Radio>
                              </Space>
                            </Radio.Group>
                          </AntdForm.Item>
                        </>
                      ) : (positionSize === 'tv' && (admin || tester)) ? (
                        <AntdForm.Item name='tvps' label='Asset Size (Plot #)' labelCol={{ span: 10 }} rules={[{ required: true, message: 'Please select Asset Size (Plot #)' }]}>
                          <Select
                            placeholder='Select Asset Size (Plot #)'
                            onChange={(v) => {
                              if (bid) {
                                const d = botForm.getFieldsValue()
                                genCode(d.name, alertId, d?.cx, d?.px, v)
                                messageApi.open({
                                  type: 'warning',
                                  content: 'Asset Size has changed, please update your TradingView Message Code',
                                  duration: 10,
                                })
                              }
                              setTvps(v)
                            }}
                          >
                            {(([...Array(20).keys()]).map((o) => (
                              <Select.Option key={o} value={o}>{o}</Select.Option>
                            )))}
                          </Select>
                        </AntdForm.Item>
                      ) : null}
                      {((positionSize !== 'fb') && (admin || tester)) && (
                        <AntdForm.Item name='balmax' label={`Max Balance Usage (${(ticker) ? ticker.balas : ''})`} labelCol={{ span: 10 }}>
                          <InputNumber min={0} />
                        </AntdForm.Item>
                      )}
                      <AntdForm.Item name='bmax' label='Buy Max Lot (X)' rules={[{ required: true, message: 'Please input Buy Max Lot' }]}>
                        <InputNumber
                          min={0}
                        />
                      </AntdForm.Item>
                      {(apiType !== 'spot') && (
                        <AntdForm.Item name='smax' label='Sell Max Lot (X)' rules={[{ required: true, message: 'Please input Sell Max Lot' }]}>
                          <InputNumber
                            min={0}
                          />
                        </AntdForm.Item>
                      )}
                      <AntdForm.Item name='ordt' label='Order Type' rules={[{ required: true, message: 'Please Select Order Type' }]}>
                        <Radio.Group
                          options={[
                            { label: 'Market Order', value: 'market' },
                            { label: 'Limit Order', value: 'limit' }
                          ]}
                          optionType='button'
                          buttonStyle='solid'
                          onChange={(e) => setOrderType(e.target.value)}
                        />
                      </AntdForm.Item>
                      {(admin || tester) && (
                        <AntdForm.Item name='rps' label='Record Position Size (Option)' labelCol={{ span: 10 }}>
                          <Switch checkedChildren='Yes' unCheckedChildren='No' />
                        </AntdForm.Item>
                      )}
                      {(orderType === 'limit') && (
                        <>
                          <AntdForm.Item name='ro' label='Remaining Orders Options' labelCol={{ span: 10 }} rules={[{ required: true, message: 'Remaining Orders Options' }]}>
                            <Radio.Group>
                              <Space direction='vertical'>
                                <Radio value='cobs'>Close Orders Next Buy or Sell</Radio>
                                <Radio value='no'>Not Close Orders</Radio>
                              </Space>
                            </Radio.Group>
                          </AntdForm.Item>
                          <AntdForm.Item name='io' label='First Order Offset' labelCol={{ span: 10 }} rules={[{ required: true, message: 'Please input First Order Offset' }]}>
                            <InputNumber
                              min={0}
                              precision={(ticker && ticker.pdec > 0) ? ticker.pdec : 0}
                            />
                          </AntdForm.Item>
                          <AntdForm.Item name='so' label='Step Order Offset' labelCol={{ span: 10 }} rules={[{ required: true, message: 'Please input Step Order Offset' }]}>
                            <InputNumber
                              min={0}
                              precision={(ticker && ticker.pdec > 0) ? ticker.pdec : 0}
                            />
                          </AntdForm.Item>
                          <AntdForm.Item name='apo' label={`Amount per Order ${(!ticker) ? '' : (['fa', 'tv'].indexOf(positionSize) !== -1) ? `(${ticker.trdas})` : `(${ticker.balas})`}`} labelCol={{ span: 10 }} rules={[{ required: true, message: 'Please input Amount per Order' }]}>
                            <InputNumber
                              min={0}
                              precision={((['fa', 'tv'].indexOf(positionSize) !== -1) && (ticker && ticker.sdec > 0)) ? ticker.sdec : ((['fb', 'vb'].indexOf(positionSize) !== -1) && (ticker && ticker.pdec > 0)) ? ticker.pdec : 0}
                            />
                          </AntdForm.Item>
                        </>
                      )}
                    </>
                  }
                ]}
              />
              {(admin) && (
                <Collapse
                  defaultActiveKey={['1']}
                  expandIconPosition='end'
                  items={[
                    {
                      key: '1',
                      label: 'Activation Setting',
                      extra: <SettingOutlined />,
                      children: <>
                        <Row>
                          <Col span={12}>
                            <AntdForm.Item name='test' label='Enable Test' labelCol={{ span: 10 }}>
                              <Switch checkedChildren='Yes' unCheckedChildren='No' />
                            </AntdForm.Item>
                          </Col>
                          <Col span={12}></Col>
                        </Row>
                      </>
                    }
                  ]}
                />
              )}
              <div style={{ textAlign: 'center' }}>
                <Button loading={btnLoading} style={{ minWidth: 200, borderRadius: '4px', height: 40, marginTop: 20 }} onClick={() => botForm.submit()} type='primary'><SaveOutlined />{(botId) ? 'Save' : 'Generate Code and Save'}</Button>
                {(botId) && (
                  <>
                    <Button loading={btnLoading} color={(active) ? 'danger' : 'primary'} variant='outlined' style={{ borderRadius: '4px', height: 40, marginTop: 20, marginLeft: 16 }} onClick={() => updateStatus(!active, false)} >{(!active) ? <PlayCircleOutlined /> : <PauseCircleOutlined />}{(active) ? 'Disable' : 'Enable'} Bot</Button>
                    {(active && ((Date.now() - (value.date.seconds * 1000)) > (24 * 60 * 60 * 1000))) && (
                      <Button loading={btnLoading} color={(active) ? 'danger' : 'primary'} variant='outlined' style={{ borderRadius: '4px', height: 40, marginTop: 20, marginLeft: 16 }}
                        onClick={() => {
                          modal.confirm({
                            title: 'Disable Bot and Close Position',
                            icon: <StopOutlined style={{ color: '#ff4d4f' }} />,
                            content: 'Are you sure to close position?',
                            okText: 'Yes',
                            cancelText: 'No',
                            onOk: () => {
                              updateStatus(false, true)
                            }
                          })
                        }}
                      ><StopOutlined />Disable Bot and Close Position</Button>
                    )}
                    <Button loading={btnLoading} style={{ borderRadius: '4px', height: 40, marginTop: 20, marginLeft: 16 }} onClick={() => downloadJson()} ><DownloadOutlined />Download</Button>
                  </>
                )}
                <Button loading={btnLoading} style={{ borderRadius: '4px', height: 40, marginTop: 20, marginLeft: 16 }} onClick={() => navigate(-1)}><CloseOutlined />Close</Button>
              </div>
            </AntdForm>
          </Card>
        </Col>
        <Col flex='400px'>
          {(!botId) ? (
            <Row>
              <Col span={24}>
                <Card
                  title='Upload Bot from File'
                  styles={{
                    header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
                    body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
                  }}
                >
                  <Upload.Dragger
                    name='file'
                    accept='.json'
                    multiple={false}
                    showUploadList={false}
                    onChange={(e) => uploadJson(e)}
                  >
                    <p className='ant-upload-drag-icon'><InboxOutlined /></p>
                    <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                  </Upload.Dragger>
                </Card>
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col span={24}>
                  <Card
                    title='TradingView Webhook URL'
                    styles={{
                      header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#ffc53d', color: '#fdfdfd' },
                      body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
                    }}
                  >
                    <Input.TextArea value={apiUri} autoSize={{ minRows: 2 }} />
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Card
                    title='TradingView Message Code'
                    styles={{
                      header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#ffc53d', color: '#fdfdfd' },
                      body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
                    }}
                  >
                    <Input.TextArea value={messageCode} autoSize={{ minRows: 7 }} />
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      {contextHolderMessage}
      {contextHolderModal}
    </div>
  )
}

export default Form
