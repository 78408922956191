const billingCoin = [
  {
    value: 'USDT',
    networks: [
      {
        value: 'BSC',
        label: 'BNB Smart Chain (BEP20)',
        address: [
          'bc1q8yx582e7d57zagv8fz3dgcuckagnxcl3ckutlkbcs'
        ]
      },
      {
        value: 'OP',
        label: 'Optimism (OP)',
        address: [
          'bc1q8yx582e7d57zagv8fz3dgcuckagnxcl3ckutlkpolygon'
        ]
      }
    ]
  }
]

const billingStatus = {
  'pending': 'Pending',
  'reject': 'Not Acceptable',
  'success': 'Success'
}

export {
  billingCoin,
  billingStatus
}
