import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Card, Table, Button, Skeleton } from 'antd'
import { DollarOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'

import { getDocs, collection, query, where, limit } from 'firebase/firestore'

import { useAuth } from '../../contexts/AuthContextProvider'
import { billingStatus } from '../../const/billing'
import { dateFormat } from '../../const/misc'

import './Dashboard.css'

const List = () => {
  const navigate = useNavigate()
  const { db } = useAuth()
  const { aid } = useParams()

  const { value } = useAsync(async () => {
    const snap = await getDocs(query(collection(db, 'billings'), where('from', '==', aid), where('c', '==', 'USDT'), limit(20)))
    return snap.docs.map((doc) => {
      const d = doc.data()
      return {
        ...d,
        id: doc.id,
        am: d.am + (d?.aam ?? 0)
      }
    })
  }, [])

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      width: 100,
      render: text => dayjs(text.seconds * 1000).format(dateFormat)
    },
    {
      title: 'Coin',
      dataIndex: 'c',
      width: 100
    },
    {
      title: 'Network',
      dataIndex: 'n',
      width: 100
    },
    {
      title: 'Address',
      dataIndex: 'from'
    },
    {
      title: 'Billed',
      dataIndex: 't',
      width: 100
    },
    {
      title: 'Paid',
      dataIndex: 'am',
      width: 100,
      render: data => new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(data)
    },
    {
      title: 'Status',
      dataIndex: 's',
      width: 130,
      render: (text) => <span style={{ color: (text === 'success') ? '#15dd15' : '#dd1515' }}>{billingStatus[text]}</span>
    },
    {
      title: 'Remark',
      dataIndex: 'r',
      width: 200
    },
    {
      title: 'Operation',
      dataIndex: 'Operation',
      render: (_, row) => (
        <div>
          <Button style={{ borderRadius: '4px', marginLeft: '16px' }} type='primary' size='middle' onClick={() => navigate(`/admin/dashboard/billings/${row.id}/approve`)}><DollarOutlined /></Button>
        </div>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div className='dashboard-page'>
      <Card
        title='Billing History'
        styles={{
          header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
          body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
        }}
      >
        <Table
          bordered
          rowKey='id'
          columns={columns}
          dataSource={value}
        />
      </Card>
    </div>
  )
}

export default List
